import React, { Component } from 'react';

import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import { isNull } from 'lodash';
import IRConfigAlert from './IRConfigAlert';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import Cookies from 'js-cookie';

import * as authActions from '../../redux/actions/authentication';
import * as profileActions from '../../redux/actions/profile';
import * as messagesActions from '../../redux/actions/messages';
import * as componentActions from '../../redux/actions/components';
import * as searchActions from '../../redux/actions/search';
import { getMyCareerPreppedStatus } from '../../redux/actions/my-careerprepped-status';

import { withHooks } from '../../utils/withHooks';

import Header from '../navbar/Header';
import Login from '../auth/Login';
import RequestInvite from '../auth/RequestInvite';
import Registration from '../auth/Registration';
import HomeFooter from '../../components/home/HomeFooter';
import PreviewAvatarModal from '../common/PreviewAvatarModal';
import MainNavigation from '../../containers/navbar/MainNavigation';
import RecruiterListModal from '../industry-mentor/RecruiterListModal';
import CookiesConsent from '../../components/auth/CookiesConsent';

const hideNavLookupArray = [
  'invitation-password',
  'signup',
  'new-password',
  'group-admin-invite',
];

class MainLayout extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    viewAvatarModal: PropTypes.object.isRequired,
    modal: PropTypes.string,
    isMobile: PropTypes.bool,
    industryRepresentativeConfiguration: PropTypes.object,
    toggleLoginModal: PropTypes.func,
    myCareerPreppedStatus: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dropdownOpenProfile: false,
      dropdownOpenBuilders: false,
      isCookiesConsentSet: Cookies.get('cookiesConsent')
    };

    this.hideLoginModal = this.hideLoginModal.bind(this);
    this.toggleSignUpModal = this.toggleSignUpModal.bind(this);
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
    this.toggleRequestInvite = this.toggleRequestInvite.bind(this);
    this.redirectToSearch = this.redirectToSearch.bind(this);
    this.closePreviewAvatar = this.closePreviewAvatar.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.showWidget = this.showWidget.bind(this);
    this.onAcceptCookiesConsent = this.onAcceptCookiesConsent.bind(this);
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);

    const { isAuthenticated, actions, currentUser, location } = this.props;
    /* eslint-disable no-undef */
    const [, path, subpath] = location.pathname.split('/');

    if (
      isAuthenticated &&
      !(path === 'auth' && hideNavLookupArray.includes(subpath))
    ) {
      actions.profileRequest(currentUser.id).then(() => {
        if (this.props.profile.role === 'industry-representative')
          actions.requestIRSettings(currentUser.id).then(() => {
            const IRConfigBarVisibility = localStorage.getItem('IRConfigBar');
            if (IRConfigBarVisibility !== 'false')
              localStorage.setItem('IRConfigBar', 'true');
          });
      });
    }
  }

  componentDidMount() {
    const userAgent = window.navigator.userAgent;

    const userWarning =
      'CareerPrepped is optimized for use on Google Chrome. For the best user experience, we recommend you use the Chrome browser. If you don\'t have it, you can download it for free.';

    if (userAgent.indexOf('Trident') > -1) {
      if (confirm(userWarning)) {
        window.location = 'https://www.google.com/chrome/browser/index.html';
      }
    }

    this.props.actions.toggleMobileView(window.innerWidth <= 990);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isAuthenticated &&
      nextProps.isAuthenticated !== this.props.isAuthenticated
    ) {
      let nextLocation = this.props.location.pathname;

      if (nextLocation === '/') {
        nextLocation = '/home';

        this.props.navigate(nextLocation, { replace: true });
      }
    }

    if (
      (!_isEmpty(nextProps.profile)) &&
        (nextProps.location.pathname === '/home') &&
        (!nextProps.myCareerPreppedStatus.isRequesting) &&
        (_isEmpty(nextProps.myCareerPreppedStatus.data))) {
      this.props.actions.getMyCareerPreppedStatus(nextProps.profile.id);
    }

    if (localStorage.getItem('IRSettingsUpdated') === 'true' || (
      nextProps.isAuthenticated !== this.props.isAuthenticated &&
      nextProps.currentUser.id !== nextProps.profile.id &&
      !_isEmpty(nextProps.currentUser))
    ) {
      localStorage.removeItem('IRSettingsUpdated');
      this.props.actions.profileRequest(nextProps.currentUser.id).then(() => {
        if (this.props.profile.role === 'industry-representative')
          this.props.actions
            .requestIRSettings(nextProps.currentUser.id)
            .then(() => {
              const IRConfigBarVisibility = localStorage.getItem('IRConfigBar');
              if (IRConfigBarVisibility !== 'false')
                localStorage.setItem('IRConfigBar', 'true');
            });
      });
    }

    if (
      this.props.isMobile !== nextProps.isMobile &&
      nextProps.isMobile !== window.innerWidth <= 1024
    )
      this.props.actions.toggleMobileView(window.innerWidth <= 1024);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    const { isMobile, actions } = this.props;
    if (isMobile !== window.innerWidth <= 1024)
      actions.toggleMobileView(window.innerWidth <= 1024);
  }

  hideLoginModal() {
    this.props.actions.closeModal();
  }

  toggleLoginModal() {
    this.props.actions.openModal('login-modal');
  }

  toggleSignUpModal() {
    if (this.props.modal === 'signup-modal') {
      this.props.actions.closeModal();
      return;
    }

    this.props.actions.openModal('signup-modal');
  }

  closePreviewAvatar() {
    this.props.actions.toggleAvatarModal();
  }

  showSearchForm(location) {
    return location.pathname != '/home';
  }

  toggleRequestInvite() {
    this.props.actions.openModal('invite-modal');
  }

  redirectToSearch() {
    this.props.actions.clearSearchReducer();
    this.props.navigate('/search');
  }

  showWidget() {
    window.groove.widget.open();
  }

  onAcceptCookiesConsent(event) {
    event.preventDefault();

    Cookies.set('cookiesConsent', true, { expires: 365 });
    this.setState({ isCookiesConsentSet: true });
  }



  render() {
    const {
      isAuthenticated,
      profile,
      modal,
      params,
      viewAvatarModal,
      isMobile,
      location: { pathname },
      industryRepresentativeConfiguration,
    } = this.props;

    const isLoginModalOpen = modal && modal === 'login-modal';
    const isInviteModalOpen = modal && modal === 'invite-modal';
    const isSignUpModalOpen = modal && modal === 'signup-modal';
    const isOpenRecruiterListModal = modal === 'recruiter-list-modal';

    const showPreviewAvatar =
      viewAvatarModal &&
      viewAvatarModal.status &&
      Object.keys(viewAvatarModal.data).length > 0;

    let isFeedbackRequestsPaused = false;

    if (!_isUndefined(industryRepresentativeConfiguration) && (industryRepresentativeConfiguration.pauseEndDate !== null)) {
      const now = moment();
      const { pauseStartDate, pauseEndDate } = industryRepresentativeConfiguration;

      isFeedbackRequestsPaused = now.isBetween(pauseStartDate.date, pauseEndDate.date);
    }

    let page_prefix;
    // We need special cases for UI changes
    switch(location.pathname){
    case '/research':
      page_prefix = 'research';
      break;
    case '/ohio':
      page_prefix = 'ohio';
      break;
    case '/bpa':
      page_prefix = 'bpa';
      break;
    case '/hosa':
      page_prefix = 'bpa';
      break;
    case '/fbla':
      page_prefix = 'bpa';
      break;
    case '/saylor':
    case '/deca':
    case '/skillsusa':
      page_prefix = 'bpa';
      break;
    default:
      page_prefix = '';
      break;
    }
    const isOhioPage = pathname.includes('ohio');
    const isBPAPage = pathname.includes('bpa');
    const isFblaPage = pathname.includes('fbla');
    const isHosaPage = pathname.includes('hosa');
    const isSaylorPage = pathname.includes('saylor');
    const isDecaPage = pathname.includes('deca');
    const isSkillsPage = pathname.includes('skillsusa');

    const irAlertCondition =
      (
        (!_isUndefined(industryRepresentativeConfiguration) &&
          industryRepresentativeConfiguration.disableFeedbackRequests === 1) ||
        isFeedbackRequestsPaused
      );

    const isActiveIndividualSubscription = (profile.role === 'user') && (!isNull(profile.customerId)) && (profile.individualSubscriptionStatus === 'active');

    return (
      <div className={`interior essential ${this.props.isAuthenticated ? '' : ' homepage_nli'} ${page_prefix}`}>
        {showPreviewAvatar && (
          <Helmet>
            <style>{'body { overflow: hidden; }'}</style>
          </Helmet>
        )}

        {isLoginModalOpen && (
          <Login
            linkParams={params.splat}
            showLoginModal={isLoginModalOpen}
            toggleLoginModal={this.hideLoginModal} />
        )}

        {(!this.state.isCookiesConsentSet) && (pathname !== '/privacy') && (
          <Modal isOpen={!this.state.isCookiesConsentSet}>
            <CookiesConsent onAcceptCookiesConsent={this.onAcceptCookiesConsent}/>
          </Modal>
        )}

        {isInviteModalOpen && (
          <RequestInvite
            pathname={pathname}
            showSignupModal={isInviteModalOpen}
            toggleSignupModal={this.hideLoginModal} />
        )}

        {isSignUpModalOpen &&
          <Registration toggleSignUpModal={this.toggleSignUpModal}/>}

        <Header
          isMobile={isMobile}
          toggleLoginModal={this.toggleLoginModal}
          toggleSignUpModal={this.toggleSignUpModal}
          toggleRequestInvite={this.toggleRequestInvite}
          currentUser={profile}
          isAuthenticated={isAuthenticated}
          redirectToSearch={this.redirectToSearch} />

        {((isAuthenticated && isNull(profile.individualSubscriptionStatus)) ||
          (isAuthenticated && (profile.role === 'institution-admin')) ||
          (isAuthenticated && isActiveIndividualSubscription)) &&
          <MainNavigation profile={profile} />}

        {!isOhioPage || !isBPAPage || !isHosaPage || !isFblaPage || !isSaylorPage || !isDecaPage || !isSkillsPage &&
        <div className="widget-container">
          <button className="widget-btn" onClick={this.showWidget}>
            Help&nbsp;<i className="far fa-question-circle" />
          </button>
        </div>}

        {irAlertCondition && profile.role === 'industry-representative' && (
          <IRConfigAlert
            profile={profile}
            actions={this.props.actions}
            industryRepresentativeConfiguration={industryRepresentativeConfiguration} />
        )}

        <Outlet />

        <HomeFooter />

        {showPreviewAvatar &&
          <PreviewAvatarModal
            onCloseRequest={this.closePreviewAvatar}>
            <div className="image-modal">
              <img
                className="mx-auto d-block"
                src={viewAvatarModal.data.avatar}/>
            </div>
          </PreviewAvatarModal>}

        {isOpenRecruiterListModal && <RecruiterListModal/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.data.currentUser,
    profile: state.profile.data,
    industryRepresentativeConfiguration: state.profile.industryRepresentativeConfiguration,
    isAuthenticated: state.auth.isAuthenticated,
    modal: state.components.modal,
    isMobile: state.components.isMobile,
    viewAvatarModal: state.components.viewAvatarModal,
    myCareerPreppedStatus: state.myCareerPreppedStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  const actions = Object.assign({}, {
    ...authActions,
    ...profileActions,
    ...messagesActions,
    ...componentActions,
    ...searchActions,
    getMyCareerPreppedStatus
  });

  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHooks(MainLayout));
