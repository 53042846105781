import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Helmet } from 'react-helmet';
import { withHooks } from '../../utils/withHooks';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

import SkillsToCareersSignUpForm from '../../components/auth/SkillsToCareersSignUpForm';

import Validator from '../../validator';

import PropTypes from 'prop-types';
import { isEmpty, isUndefined } from 'lodash';

import { requestSelfRegistration } from '../../redux/actions/registration';
import { fetchSkillsToCareersInitiativeGroups } from '../../redux/actions/skills-to-careers-initiative-groups';

let credentials = { email: '', confirmEmail: '' };

class SkillsUSA extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    registration: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      popovers: [false, false, false, false, false,false, false, false, false, false],
      isOpenSignUpModal: false,
      displayAlertType: '',
      credentials: {
        email: '',
        confirmEmail: '',
        groupCode: ''
      },
      errors: {}
    };

    this.togglePopover1 = this.togglePopover1.bind(this);
    this.togglePopover2 = this.togglePopover2.bind(this);
    this.togglePopover3 = this.togglePopover3.bind(this);
    this.togglePopover4 = this.togglePopover4.bind(this);
    this.togglePopover5 = this.togglePopover5.bind(this);
    this.togglePopover6 = this.togglePopover6.bind(this);
    this.togglePopover7 = this.togglePopover7.bind(this);
    this.togglePopover8 = this.togglePopover8.bind(this);
    this.togglePopover9 = this.togglePopover9.bind(this);
    this.togglePopover10 = this.togglePopover10.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.toggleSignUpModal = this.toggleSignUpModal.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.dismissAlert = this.dismissAlert.bind(this);
  }

  componentWillMount(){
    document.body.classList.add('bpa-signup');

    const { location: { pathname } } = this.props;

    const groupName = pathname.slice(1);

    this.props
      .actions
      .fetchSkillsToCareersInitiativeGroups(groupName)
      .then(state => this.setState({
        credentials: {
          ...this.state.credentials,
          groupCode: state.skillsToCareersInitiativeGroups.data[0].inviteCode
        }
      }));
  }

  componentWillUnmount(){
    document.body.classList.remove('bpa-signup');
  }

  toggleSignUpModal(event) {
    event.preventDefault();

    this.setState({ isOpenSignUpModal: !this.state.isOpenSignUpModal });
  }

  isValid(field = null) {
    const { credentials, errors: prevErrors } = this.state;

    const validate = Validator.createValidator({
      email: ['required', 'email'],
      confirmEmail: ['required', 'email', 'match|email'],
      groupCode: ['required']
    }, credentials, field);

    const { errors, isValid } = validate;

    if (field && Object.keys(errors).length === 0) {
      delete prevErrors[field];
    }

    this.setState({ errors: { ...prevErrors, ...errors } });

    return isValid;
  }

  onChange(event) {
    const { target: { name, value } } = event;

    const credentials = { ...this.state.credentials, [name]: value };

    this.setState({ credentials }, () => this.isValid(name));
  }

  handleKeyPress(event) {
    if (event.key === 'Enter')
      return this.onSubmit(event);
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.isValid()) return false;

    this.props
      .actions
      .requestSelfRegistration(this.state.credentials)
      .then(() => {
        const { registration } = this.props;
        let displayAlertType = 'success';

        if ((! isEmpty(registration.error)) && (! isUndefined(registration.error.errorType))) {
          credentials = this.state.credentials;
          displayAlertType = (registration.error.errorType === 'DuplicateSelfRegistrationException') ? 'warning' : 'danger';
        }

        this.setState({ credentials, displayAlertType });
      })
      .catch((error) => {
        let displayAlertType = 'danger';

        if ((! isEmpty(error)) && (! isUndefined(error.errorType))) {
          displayAlertType = (error.errorType === 'DuplicateSelfRegistrationException') ? 'warning' : displayAlertType;
        }

        this.setState({ displayAlertType });
      });
  }

  dismissAlert() {
    this.setState({ displayAlertType: '' });
  }

  togglePopover1() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 0 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover2() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 1 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover3() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 2 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover4() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 3 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover5() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 4 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover6() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 5 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover7() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 6 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover8() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 7 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover9() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 8 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }
  togglePopover10() {
    this.setState((prevState) => {
      const newPopovers = prevState.popovers.map((isOpen, i) => (i === 9 ? !isOpen : isOpen));
      return { popovers: newPopovers };
    });
  }

  handleLinkClick(event) {
    event.preventDefault();
  }

  render() {
    const imgTDnvde = require('../../assets/images/acte_skillsUSA_logo.jpg');
    const imgJenny = require('../../assets/images/homepage/img-testimonial-jennyj.png');
    const imgJeremy = require('../../assets/images/homepage/img-testimonial-jeremyb.png');
    const imgSarah = require('../../assets/images/homepage/img-testimonial-sarahk.png');
    const imgHomepageUnleash = require('../../assets/images/homepage/individual/img-homepage-potential.jpg');
    const imgHomepageHighlight = require('../../assets/images/homepage/individual/img-homepage-highlight.jpg');
    const imgHomepageSettle = require('../../assets/images/homepage/individual/img-homepage-settle.jpg');
    const imgHomepageUnlock = require('../../assets/images/homepage/individual/img-homepage-unlock.jpg');
    const { registration } = this.props;
    const {
      popovers,
      errors,
      credentials,
      isOpenSignUpModal,
      displayAlertType
    } = this.state;

    return (
      <div className="info" style={{paddingTop: 0, paddingBottom: 0, marginBottom: 0,marginTop:0}}>
        <Helmet title="CareerPrepped - Business Professionals of America"/>
        <article className="bpa_sponsored">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1>
                  Stand out. Get hired. Get ahead. <br />
                  CareerPrepped is free for <br />
                  all SkillsUSA members.
                </h1>
              </div>
              <div className="col-lg-6">
                <img src={imgTDnvde} className="img-fluid" alt="Business Professionals of America" />
              </div>
            </div>
          </div>
        </article>
        <article className="section bluegrey">
          <div className="container">
            <div className="section-heading">
              <h1>Sign up today!</h1>
              <p>You get free lifetime access to CareerPrepped (normally $96/year).</p>
              <div className="talent-developers">
                <div className="get-started">
                  <a
                    href="#"
                    className="orange-btn toolkit"
                    onClick={this.toggleSignUpModal}>
                    Sign Up for Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="section white">  
          <div className="container">
            <div className="section-heading">
              <h2>Unleash your potential. </h2>
              <h2>Get the jobs you deserve and never let your skills go unnoticed.</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="section-container">
                  <div className="section-container-item order-sm-1">
                    <img src={imgHomepageUnleash} className="img-fluid d-block mx-auto" alt="CareerPrepped - Unleash your potential" />
                  </div>
                  <div className="section-container-item order-sm-2 p-5">
                    <p>
                      Today, where machines do more work for us and AI makes cookie-cutter resumes for everyone, it's harder for employers to know a person's true abilities.
                    </p>
                    <p>
                      Plus, as AI takes center stage, human skills like communication and teamwork are now more crucial in the workplace.
                    </p>
                    <p>
                      You're skilled. Your potential is limitless. But getting employers to see it shouldn't be so hard.  
                    </p>
                    <p>
                      Our tools help you showcase your skills in a way that's impossible to ignore. We make your value crystal clear to employers, so you can land the opportunities you deserve.
                    </p>
                    <div className="talent-developers">
                      <div className="get-started">
                        <a
                          href="#"
                          className="orange-btn toolkit"
                          onClick={this.toggleSignUpModal}>
                          Sign Up for Free
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="section grey">
          <div className="container">
            <div className="section-heading">
              <h2>Highlight your value.</h2>
              <h2>Focus on what matters most - your skills.</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="section-container">
                  <div className="section-container-item order-sm-1 order-md-2">
                    <img src={imgHomepageHighlight} alt="CareerPrepped - Highlight your value" className="img-fluid d-block mx-auto" />
                  </div>
                  <div className="section-container-item order-sm-2 order-md-1 p-5">
                    <p>
                      Showcase all that you offer! Your skills are your ticket to success. Whether gained through life, education, or work, they're your most valuable asset. Make them visible!
                    </p>
                    <p>
                      We help you build, capture, and prove your skills, so you stand out from the crowd. Get feedback from your network and industry pros on the skills you prove to know your strengths and where you need to level up.   
                    </p>
                    <p>
                      Plus, get tools to enhance and highlight your "human skills" – essential skills every employer craves.
                    </p>
                    <p>
                      Start building your lifelong record of skills. Take it with you as you grow your career and keep adding to it as you grow your skills. 
                    </p>
                    <div className="talent-developers">
                      <div className="get-started">
                        <a
                          href="#"
                          className="orange-btn toolkit"
                          onClick={this.toggleSignUpModal}>
                          Sign Up for Free
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="section white">  
          <div className="container">
            <div className="section-heading">
              <h2>Don't settle for blending in.</h2>
              <h2>Stand out by going beyond a basic profile or resume.</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="section-container">
                  <div className="section-container-item">
                    <img src={imgHomepageSettle} className="img-fluid d-block mx-auto" alt="CareerPrepped - Stand out" />
                  </div>
                  <div className="section-container-item p-5">
                    <p>
                      Create your own Career Site with CareerPrepped! Unlike a basic profile, your Career Site links to multiple pages to showcase your skills and achievements beyond your education and job history.
                    </p>
                    <p>
                      With just a click, transform your Career Site into an interactive resume. It has clickable links to prove your skills with evidence. Impress employers even before you interview!
                    </p>
                    <p>
                      Connect any of your online profiles to your Career Site. This way, your entire online presence is together in one place. Easily share it with employers!
                    </p>
                    <div className="talent-developers">
                      <div className="get-started">
                        <a
                          href="#"
                          className="orange-btn toolkit"
                          onClick={this.toggleSignUpModal}>
                          Sign Up for Free
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="section grey-1">
          <div className="container">
            <div className="section-heading">
              <h2>Unlock new opportunities.</h2>
              <h2>Look beyond traditional job boards and networking sites.</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="section-container">
                  <div className="section-container-item order-sm-1 order-md-2">
                    <img src={imgHomepageUnlock} alt="CareerPrepped - Unlock opportunities" className="img-fluid d-block mx-auto" />
                  </div>
                  <div className="section-container-item order-sm-2 order-md-1 p-5">
                    <p>
                      CareerPrepped's Job Match tool, powered by AI, looks through thousands of job sites to give you personalized job matches that fit your goals and preferences. It helps you discover jobs you might not have known existed.
                    </p>
                    <p>
                      And our Job Tracker tool keeps all your job applications in one spot. Save the jobs you like, jot down notes, and store important contacts. Track every step from applying to and landing the job you want.
                    </p>
                    <div className="talent-developers">
                      <div className="get-started">
                        <a
                          href="#"
                          className="orange-btn toolkit"
                          onClick={this.toggleSignUpModal}>
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="section blue">
          <div className="container">
            <div className="section-heading">
              <h2 className="mb-0">CareerPrepped offers you a complete toolkit to achieve lifelong career success.</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="section-container">
                  <div className="section-container-item order-sm-1 order-md-2">
                    <div className="section-bullets">
                      <ul className="fa-ul">
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover1"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Career Site</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[0]}
                            target="Popover1"
                            trigger="hover"
                            toggle={this.togglePopover1}>
                            <PopoverHeader>Career Site</PopoverHeader>
                            <PopoverBody>Don't settle for blending in. Say no to boring resumes and basic profiles. Set yourself apart from the crowd by showing off what makes you unique. Create your own personal Career Site with links to multiple pages to showcase your skills and achievements beyond your education and job history. It's time to make a statement and stand out!</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover2"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Resume Builder</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[1]}
                            target="Popover2"
                            trigger="hover"
                            toggle={this.togglePopover2}>
                            <PopoverHeader>Resume Builder</PopoverHeader>
                            <PopoverBody>With just a click, transform your Career Site into an interactive resume. It has clickable links to back up your skills with evidence. Edit it on the fly to save countless resumes customized for the job, company, or hiring manager you're targeting. Shine bright with a personalized resume for each job you go after.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover3"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Job Match</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[2]}
                            target="Popover3"
                            trigger="hover"
                            toggle={this.togglePopover3}>
                            <PopoverHeader>Job Match</PopoverHeader>
                            <PopoverBody>Discover your dream job with our Job Match tool, powered by AI. It looks through thousands of job sites to find the perfect match for you based on your goals and preferences. Uncover exciting jobs you might not have known existed.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover4"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Job Tracker</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[3]}
                            target="Popover4"
                            trigger="hover"
                            toggle={this.togglePopover4}>
                            <PopoverHeader>Job Tracker</PopoverHeader>
                            <PopoverBody>Keep all your job applications in one organized spot. Save the jobs you like, jot down notes, and keep contact details handy. Track your journey from applying to landing your dream job!</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover5"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Interview Prep Video Library</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[4]}
                            target="Popover5"
                            trigger="hover"
                            toggle={this.togglePopover5}>
                            <PopoverHeader>Interview Prep Video Library</PopoverHeader>
                            <PopoverBody>Get ready to ace your next interview! Watch videos to learn how to answer common interview questions like a pro. See examples of both weak and strong responses with helpful feedback. Get the tools you need to shine and turn your interview into a job offer!</PopoverBody>
                          </Popover>
                        </li>
                      </ul>

                      <ul className="fa-ul">
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover6"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Career Portfolio</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[5]}
                            target="Popover6"
                            trigger="hover"
                            toggle={this.togglePopover6}>
                            <PopoverHeader>Career Portfolio</PopoverHeader>
                            <PopoverBody>Unlock the power of your achievements! Build a digital treasure trove to showcase proof of your skills and accomplishments. With privacy controls and unlimited storage, you can store school projects, work samples, credentials, awards and more. Let your achievements speak louder than words on a resume!</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover7"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Skill Builders for Essential Skills</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[6]}
                            target="Popover7"
                            trigger="hover"
                            toggle={this.togglePopover7}>
                            <PopoverHeader>Skill Builders for Essential Skills</PopoverHeader>
                            <PopoverBody>Put a spotlight on your &quot;human skills&quot; - these are among the essential skills all employers crave. And they are in higher demand in today's AI-driven world. Get ahead with 40+ Skill Builders to boost your essentials skills and set yourself apart from your competition. These are the skills you need for lifelong career success, as reported by hundreds of industry surveys nationwide.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover8"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Skill Badges for Essential Skills</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[7]}
                            target="Popover8"
                            trigger="hover"
                            toggle={this.togglePopover8}>
                            <PopoverHeader>Skill Badges for Essential Skills</PopoverHeader>
                            <PopoverBody>Claim Skill Badges to prove your essential skills with real evidence that others can review and endorse. Make sure employers know without a doubt that you have the skills research proves they're looking for but have a hard time finding in job candidates.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover9"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Skill Feedback & Ratings</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[8]}
                            target="Popover9"
                            trigger="hover"
                            toggle={this.togglePopover9}>
                            <PopoverHeader>Skill Feedback & Ratings</PopoverHeader>
                            <PopoverBody>Get your claimed skills recognized through the power of feedback. CareerPrepped lets you provide evidence to prove each skill you have and then get feedback from community members and industry mentors on how well you’ve proven them. With feedback, learn your strengths and where you need to level up. Improve your skill evidence and increase your feedback ratings to showcase your proven skills to employers.</PopoverBody>
                          </Popover>
                        </li>
                        <li>
                          <i className="fa-li fa fa-genderless" />
                          <a
                            href="#"
                            id="Popover10"
                            style={{color: 'white', textDecoration: 'none', cursor: 'pointer'}}
                            className="tooltip-desc"
                            onClick={this.handleLinkClick}
                            trigger="hover">Skill Endorsements & Testimonials</a>
                          <Popover
                            placement="top"
                            isOpen={popovers[9]}
                            target="Popover10"
                            trigger="hover"
                            toggle={this.togglePopover10}>
                            <PopoverHeader>Skill Endorsements & Testimonials</PopoverHeader>
                            <PopoverBody>Get strong endorsements and testimonials from those who've seen your skills firsthand. Don't settle for weak praises from strangers. Verified endorsements, backed by proof, help you stand out and impress employers. Start collecting them and make your claimed skills more credible!</PopoverBody>
                          </Popover>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="testimonials">
          <h2 className="text-center">What Community Members Are Saying.</h2>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-xl-4">
                <div className="testimonial-item">
                  <div className="testimonial-item_profile">
                    <img src={imgSarah} className="img-fluid" alt="CareerPrepped helped prove skills"/>
                  </div>
                  <div className="testimonial-item_quote">
                    CareerPrepped helped me realize if I can prove my skills, it doesn't matter how I got them. The feedback tool helped me understand my strengths, how others view my skills, and how I can improve so I can stand out when applying for jobs.
                  </div>
                  <div className="testimonial-item_author">
                    Sarah K.
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12 col-xl-4">
                <div className="testimonial-item">
                  <div className="testimonial-item_profile">
                    <img src={imgJeremy} className="img-fluid" alt="CareerPrepped is a one-stop shop"/>
                  </div>
                  <div className="testimonial-item_quote">
                    I feel like I found a one-stop shop to manage my career. I can capture my skills, show my work samples, get feedback from the community, get job matches, track my job search progress, and more - it's super convenient.
                  </div>
                  <div className="testimonial-item_author">
                    Jeremy B.
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12 col-xl-4">
                <div className="testimonial-item">
                  <div className="testimonial-item_profile">
                    <img src={imgJenny} className="img-fluid" alt="Careerprepped - Life experiences matter"/>
                  </div>
                  <div className="testimonial-item_quote">
                    I realized the skills I've developed from life experience matter. The tools are great but the biggest surprise for me was how much my confidence has been boosted. I feel I have more control over my story, my career, and my future.
                  </div>
                  <div className="testimonial-item_author">
                    Jenny J.
                  </div>
                </div>
              </div>
            </div>
            <div className="talent-developers" style={{'background':'none','marginTop':'30px'}}>
              <div className="get-started text-center">
                <a
                  href="#"
                  className="orange-btn toolkit"
                  onClick={this.toggleSignUpModal}>
                  Sign Up for Free
                </a>
              </div>
            </div>
          </div>
        </article>

        {this.state.isOpenSignUpModal &&
          <SkillsToCareersSignUpForm
            errors={errors}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            registration={registration}
            credentials={credentials}
            toggleSignUpModal={this.toggleSignUpModal}
            isOpenSignUpModal={isOpenSignUpModal}
            displayAlertType={displayAlertType}
            dismissAlert={this.dismissAlert}
            handleKeyPress={this.handleKeyPress}/>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  registration: state.registration
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    requestSelfRegistration,
    fetchSkillsToCareersInitiativeGroups
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SkillsUSA));
